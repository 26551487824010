import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
const IndexPage = () => {
  
  useEffect(() => {
    window.slider()
    
  }, [])
  return (
    <Layout show_slider={true}>
      <SEO title="Inicio" />
      <div
        className={"section mcb-section"}
        style={{
          paddingTop: "100px",
          paddingBottom: "60px",
          backgroundColor: "#e1e4e6",
          backgroundImage: "url(/images/home_hr_sectionbg1.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
        }}
      >
        <div className={"section_wrapper mcb-section-inner"}>
          <div className={"wrap mcb-wrap one  valign-top clearfix"}>
            <div className={"mcb-wrap-inner"}>
              <div
                className={"column mcb-column one-second column_placeholder"}
              >
                <div className={"placeholder"}>
                  <img alt="" src={"/images/index1.png"} />
                </div>
              </div>
              <div className={"column mcb-column one-second column_column"}>
                <div className={"column_attr"}>
                  <div
                    className={
                      "image_frame image_item no_link scale-with-grid alignnone no_border"
                    }
                  >
                    <div className={"image_wrapper"}>
                      <img
                        className={"scale-with-grid"}
                        src={"/images/home_hr_heading.png"}
                        alt="home_hr_heading"
                        width="95"
                        height="120"
                      />
                    </div>
                  </div>
                  <hr className={"no_line"} style={{ margin: "0 auto 30px" }} />
                  <h2>Apalache Análisis</h2>
                  <p>
                    Persigue democratizar el entendimiento del mercado
                    financiero mexicano, mediante un análisis de calidad.
                  </p>
                  <hr className={"no_line"} style={{ margin: "0 auto 30px" }} />
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className={"button  button_theme button_js"}
                    href="http://www.apalache.mx/"
                  >
                    <span className={"button_label"}>
                      Acerca de Apalache Estructuración
                    </span>
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={"section mcb-section equal-height "}
        style={{ paddingTop: "100px", paddingBottom: "60px" }}
      >
        <div className={"section_wrapper mcb-section-inner"}>
          <div className={"wrap mcb-wrap one  valign-top clearfix"}>
            <div className={"mcb-wrap-inner"}>
              <h3>NUESTROS MANDATOS</h3>
              <br />
              <div
                style={{ height: "590px" }}
                className={"column mcb-column one-third column_column  "}
              >
                <div
                  className={"column_attr align_center"}
                  style={{
                    padding: "60px 13% 45px",
                    border: "3px solid #2e313f",
                    margin: "0 2%",
                  }}
                >
                  <div
                    className={
                      "image_frame image_item no_link scale-with-grid alignnone no_border"
                    }
                  >
                    <div className={"image_wrapper"}>
                      <img
                        className={"scale-with-grid"}
                        src={"/images/home_hr_iconbox1.png"}
                        alt="home_hr_iconbox1"
                        width="100"
                        height="100"
                      />
                    </div>
                  </div>
                  <hr className={"no_line"} style={{ margin: "0 auto 30px" }} />
                  <h4>VALUACIÓN INDEPENDIENTE</h4>
                  <hr className={"no_line"} style={{ margin: "0 auto 30px" }} />
                  <p>
                    Proporcionamos opiniones independientes del valor de
                    instrumentos para las inversiones de fondos nacionales e
                    internacionales de capital privado. Mediante estrictas
                    metodologías, emitimos documentos de seguimiento del fair
                    value de los activos de vehículos como Fibras y activos
                    alternativos (CKDs y CERPIs).
                  </p>
                </div>
              </div>
              <div
                style={{ height: "590px" }}
                className={"column mcb-column one-third column_column  "}
              >
                <div
                  className={"column_attr align_center"}
                  style={{
                    padding: "60px 13% 45px",
                    border: "3px solid #2e313f",
                    margin: "0 2%",
                  }}
                >
                  <div
                    className={
                      "image_frame image_item no_link scale-with-grid alignnone no_border"
                    }
                  >
                    <div className={"image_wrapper"}>
                      <img
                        className={"scale-with-grid"}
                        src={"/images/home_hr_iconbox2.png"}
                        alt="home_hr_iconbox2"
                        width="100"
                        height="100"
                      />
                    </div>
                  </div>
                  <hr className={"no_line"} style={{ margin: "0 auto 30px" }} />
                  <h4>COBERTURAS Y ANÁLISIS</h4>
                  <hr className={"no_line"} style={{ margin: "0 auto 30px" }} />
                  <p>
                    Valuamos instrumentos de capital del mercado mexicano,
                    público y privado, y proveemos soluciones de reportes, de
                    acuerdo con las necesidades de cada cliente. En el caso de
                    emisores públicos, contamos con asociaciones estratégicas
                    con 2 casas de bolsa nacionales de alto renombre y un{" "}
                    <em>broker</em>
                    global. Esto agrega valor tangible a nuestros clientes por
                    la profundidad que alcanza nuestro análisis.
                  </p>
                </div>
              </div>
              <div
                style={{ height: "590px" }}
                className={"column mcb-column one-third column_column "}
              >
                <div
                  className={"column_attr align_center"}
                  style={{
                    padding: "60px 13% 45px",
                    border: "3px solid #2e313f",
                    margin: "0 2%",
                  }}
                >
                  <div
                    className={
                      "image_frame image_item no_link scale-with-grid alignnone no_border"
                    }
                  >
                    <div className={"image_wrapper"}>
                      <img
                        className={"scale-with-grid"}
                        src={"/images/home_hr_iconbox3.png"}
                        alt="home_hr_iconbox3"
                        width="100"
                        height="100"
                      />
                    </div>
                  </div>
                  <hr className={"no_line"} style={{ margin: "0 auto 30px" }} />
                  <h4>DISEÑO Y MANEJO DE PORTAFOLIOS</h4>
                  <hr className={"no_line"} style={{ margin: "0 auto 30px" }} />
                  <p>
                    Enfoque de generación de alpha y beta para{" "}
                    <em>asset managers</em> y <em>family offices</em>. Manejo de
                    distintos perfiles de riesgo. Orientación a emisores que
                    cotizan en el NYSE y la BMV.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={"section"}
        style={{
          paddingTop: "100px",
          paddingBottom: "100px",
          backgroundColor: "#1c2229",
        }}
      >
        <div className={"row container-custom"} style={{ margin: "0 auto" }}>
          <div className={"col-md-12"}>
            <div className={"row text-center"}>
              <h3 style={{ color: "#fff" }}>
                {" "}
                Mensaje del Director de Análisis
              </h3>
              <h3 style={{ color: "#fff" }}>Jorge Plácido</h3>
            </div>
            <br />
            <div className={"row"}>
              <div className={"col-md-6"}>
                <p className={"mensaje"}>
                  Tanto el <em>hedge fund</em> más sofisticado como el
                  inversionista
                  <em>retail</em> tienen necesidades comunes a la hora de tomar
                  decisiones de inversión. Por lo que, nuestra misión es que los
                  análisis y valuaciones que llevamos a cabo sean claros,
                  objetivos, transparentes y oportunos.
                </p>
              </div>
              <div className={"col-md-6"}>
                <p className={"mensaje"}>
                  La visión de la firma es la de democratizar el entendimiento
                  del mercado financiero mexicano, mediante un análisis de
                  calidad y la desconcentración de valuaciones en pocas manos,
                  para impulsar la confianza de los inversionistas y
                  perfeccionar el binomio "riesgo - rendimiento" del mercado
                  financiero mexicano.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={"section the_content no_content"}>
        <div className={"section_wrapper"}>
          <div className={"the_content_wrapper"}></div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
